import React from "react"
import "./index.scss"

const ClientCard = ({ item }) => (
  <div className="client-wrapper">
    <div>
      <p className="quote-mark">“</p>
      <span className="title">{item.content}</span>
    </div>
    <div className="bottom-wrapper">
      <img src={item.avatar} className="avatar" alt={item.altTextClient}/>
      <div className="column">
        <span className="name">{item.name}</span>
        <span className="title">{item.title}</span>
        <div className="logo">
          <img src={item.logo} alt={item.altTextLogo}/>
        </div>
      </div>
    </div>
  </div>
)

export default ClientCard
