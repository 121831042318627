import React, { useState } from "react"
import _ from "lodash"
import Slider from "react-slick"
import { useTranslation } from "gatsby-plugin-react-i18next"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Banner from "../../components/Banner/Banner.js"
import CaseStudyCard from "../../components/CaseStudies/Card"
import ClientCard from "../../components/CaseStudies/ClientCard"
import {
  TYPE,
  caseStudiesList,
  clientList,
  carouselSettings,
} from "../../data/case-studies-setting"

import "./case-studies.scss"

const bannerImg = `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/background.png`
const bannerData = {
  title: ["Case Studies"],
  content:
    "Explore Neurond’s projects portfolio to see how we help clients revolutionize the way they work by optimizing processes, boosting efficiency, and minimizing risks with top-notch Artificial Intelligence and Data Science solutions.",
}

const CaseStudiesPage = () => {
  const { t } = useTranslation()

  const [caseStudiesType, setCaseStudiesType] = useState(TYPE.all)
  const [caseStudies, setCaseStudies] = useState(caseStudiesList)

  const handleClickTab = type => {
    setCaseStudiesType(type)
    if (type === TYPE.all) {
      return setCaseStudies(caseStudiesList)
    }
    const filteredData = _.filter(caseStudiesList, { type })
    setCaseStudies(filteredData)
  }

  return (
    <Layout>
      <SEO title={t("Case Studies")} />
      <div className="margin-bot">
        <Banner item={bannerData} backgroundImage={bannerImg} />
      </div>

      <div className="case-study-list">
        <div className="tabs">
          <button
            onClick={() => handleClickTab(TYPE.all)}
            className={caseStudiesType === TYPE.all ? "tab tab__active" : "tab"}
          >
            {t("All")}
          </button>
          <button
            onClick={() => handleClickTab(TYPE.artificialIntelligence)}
            className={
              caseStudiesType === TYPE.artificialIntelligence
                ? "tab tab__active"
                : "tab"
            }
          >
            {t("Artificial Intelligence")}
          </button>
          <button
            onClick={() => handleClickTab(TYPE.dataScience)}
            className={
              caseStudiesType === TYPE.dataScience ? "tab tab__active" : "tab"
            }
          >
            {t("Data Science")}
          </button>
        </div>
        <div className="flex-container">
          {caseStudies.map(item => {
            return (
              <div
                className="item"
                style={{ backgroundImage: `url(${item.imgLink})` }}
              >
                <div className="item__item-overlay"></div>
                <CaseStudyCard {...item} />
              </div>
            )
          })}
        </div>
      </div>
      <div className="client-list">
        <div className="client-list__header">
          <h1>{t("TRUSTED BY OUR CLIENTS")}</h1>
          <div className="line-break"></div>
        </div>
        <div className="flex-container">
          <Slider {...carouselSettings}>
            {clientList.map((item, index) => {
              return <ClientCard item={item} key={index} />
            })}
          </Slider>
        </div>
      </div>
    </Layout>
  )
}

export default CaseStudiesPage
