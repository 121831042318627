import React from "react"
import "./index.scss"
import { navigate } from "gatsby"

const CaseStudyCard = ({ logo, description, url, altText }) => {
  const handleRedirect = () => {
    navigate(url)
  }

  return (
    <div onClick={() => handleRedirect()} className="case-studies-wrapper">
      <div className="bottom-wrapper">
        <img src={logo} alt={altText ?? ""}/>
        <span>{description}</span>
      </div>
    </div>
  )
}

export default CaseStudyCard
