export const TYPE = {
  all: "All",
  artificialIntelligence: "ArtificialIntelligence",
  dataScience: "Data Science",
}

export const carouselSettings = {
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export const caseStudiesList = [
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/incloud-manga-creation-tool/title-bg.jpg`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/incloud/incloud-logo.png`,
    description: "Top-notch Cloud service provider in Japan",
    type: TYPE.dataScience,
    url: "/company/case-studies/incloud-manga-creation-tool/",
    altText: "Case Study - incloud-manga-creation-tool logo"
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/fishwell/challenges-bg.jpg`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/fishwell/fishwell-logo.png`,
    description: "A leading technology company in fish farming in Norway",
    type: TYPE.artificialIntelligence,
    url: "/company/case-studies/fishwell/",
    altText: "Case Study - Fishwell logo",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/orient-chat-assistant/title-bg.jpg`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/orient/orient-logo.png`,
    description: "Top software outsourcing company in Vietnam",
    type: TYPE.artificialIntelligence,
    url: "/company/case-studies/orient-chat-assistant/",
    altText: "Case Study - Orient chat assistant logo",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/incloud-ocr-checker/title-bg.jpg`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/incloud/incloud-logo.png`,
    description: "Top-notch Cloud service provider in Japan",
    type: TYPE.dataScience,
    url: "/company/case-studies/incloud-ocr-checker/",
    altText: "Case Study - incloud ocr checker logo"
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/incloud/incloud-bg.png`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/incloud/incloud-logo.png`,
    description: "Top-notch Cloud service provider in Japan",
    type: TYPE.artificialIntelligence,
    url: "/company/case-studies/incloud/",
    altText: "Case Study - Incloud logo"
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/bravo/title-bg.png`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/bravo/bravo-logo.png`,
    description: "Technology start-up specializing in HR solutions",
    type: TYPE.artificialIntelligence,
    url: "/company/case-studies/bravo/",
    altText: "Case Study - bravo logo",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/conexus/title-bg.png`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/conexus/conexus-logo.png`,
    description: "Making e-learning innovative and motivational",
    type: TYPE.artificialIntelligence,
    url: "/company/case-studies/conexus/",
    altText: "Case Study - Conexus logo",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/orient/impact-bg.png`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/orient/orient-logo.png`,
    description: "Top software outsourcing company in Vietnam",
    type: TYPE.artificialIntelligence,
    url: "/company/case-studies/orient/",
    altText: "Case Study - Orient logo",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/netwealth/title-bg.png`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/netwealth/netwealth-logo.png`,
    description: "One of the best Australian financial services",
    type: TYPE.dataScience,
    url: "/company/case-studies/netwealth/",
    altText: "Case Study - Netwealth logo",
  },
  {
    imgLink: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/dekra/title-bg.png`,
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/dekra/dekra-logo.png`,
    description: "Leading automotive company in the world",
    type: TYPE.dataScience,
    url: "/company/case-studies/dekra/",
    altText: "Case Study - Dekra logo",
  },
]

export const clientList = [
  {
    content:
      "Thanks to Neurond’s Dr.Parser,  we’re able to create a compelling talent management system that every HR loves.",
    avatar: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/john.png`,
    name: "John P.",
    title: "Product Development Manager",
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/bravo-logo.png`,
    altTextClient: "Case Study - John P bravo",
    altTextLogo: "Case Study - bravoSUITE logo",
  },
  {
    content:
      "Neurond’s AI team has constantly impressed us. Our team at Neurond, within a few months, became a vital and integrated part of  Fishwell, sharing the same vision and drive forward, and representing a key force of Fishwell.",
    avatar: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/thomas.png`,
    name: "Thomas H.",
    title: "CTO",
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/fishwell.png`,
    altTextClient: "Case Study -Thomas H Fishwell",
    altTextLogo: "Case Study - Fishwell logo",
  },
  {
    content:
      "Neurond’s most impressive characteristic is their passion for helping us solve our problems. They truly believe in what we’re trying to achieve. Their members see the value of our work and understand our vision.",
    avatar: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/chris.png`,
    name: "Christopher B.",
    title: "Managing Director",
    logo: `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/incloud-logo.png`,
    altTextClient: "Case Study - Christopher B Incloud",
    altTextLogo: "Case Study - incloud client logo",
  },
]
